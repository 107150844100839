@import '../../../styles/variables';
.manage-section{
  $self:&;
  position:relative;
  padding: 65px 0 40px;
  overflow: hidden;
  @media (max-width:767px){
    padding: 30px 0 30px;
  }
  &__wrapper{
    position:relative;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__header{
    max-width: 610px;
    width: 100%;
  }
  &__list{
    margin-top: 30px;
    @media (max-width:992px){
      margin-bottom: 40px;
    }
    @media (max-width:767px){
      margin-top: 10px;
    }
  }
  &__image{
    display: flex;
    position: relative;
    width: 590px;
    @media (max-width:1200px){
      max-width: 100%;
      margin:0 auto;
    }
    @media (max-width:767px){
      max-width: 350px;
    }
    img{
      position: relative;
      left: -80px;
      top:-10px;
      width: 100%;
      transition: all 0.3s ease;
      &:hover{
        transform: scale(1.02);
      }
      @media (max-width:1200px){
        left: 0px;
      }
      @media (max-width:767px){
        left: -30px;
      }
    }
  }
}

