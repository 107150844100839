/* width */
::-webkit-scrollbar {
  width: 6px; }

/* Track */
::-webkit-scrollbar-track {
  background: $main_color; }

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary_color; }

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $main_color; }
