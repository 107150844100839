@import "../../../styles/variables";
.qr-section {
  $self: &;
  position: relative;
  padding: 65px 0 85px;
  overflow: hidden;

  .col-lg-6 {
    display: flex;
    justify-content: center;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  &__button {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  &__image {
    position: relative;
    font-size: 20px;
    width: 258px;
    margin-top: 25px;
    color: $primary_color;
    font-weight: 500;
    span {
      display: block;
      position: relative;
      margin-bottom: 15px;
    }
    a {
      display: inline-flex;
      color: $primary_color;
      text-decoration: none;
      text-align: right;
      margin-top: 10px;
      &:before {
        background: $primary_color;
      }
    }
  }
}

@media (max-width: 992px) {
  .qr-section {
    .col-lg-6 {
      &:first-child {
        order: 2;
      }
      &:last-child {
        order: 1;
      }
    }
  }
}

@media (max-width: 767px) {
  .qr-section {
    padding: 30px 0 30px;

    &__image {
      width: 192px;
      svg {
        width: 100%;
        height: 192px;
      }
    }
    &__header {
      max-width: 320px;
    }
  }
}
