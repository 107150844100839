@import "../../../styles/_variables";
.list {
  $self: &;
  position: relative;

  &--default {
    list-style: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.36;
    color: $main_color;
    display: flex;
    @media (max-width: 767px) {
      font-size: 14px;
      flex-wrap: wrap;
    }
    li {
      position: relative;
      &:not(&:last-child) {
        margin-right: 25px;
      }
      &:not(&:first-child) {
        &:before {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          left: -18px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../../../assets/images/mark.svg") no-repeat;
          z-index: 1;
        }
      }
    }
  }
  &--default-big {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.36;
    color: $main_color;
    display: flex;
    list-style: none;
    @media (max-width: 767px) {
      font-size: 20px;
      flex-wrap: wrap;
      font-weight: 600;
    }
    li {
      position: relative;
      &:not(&:last-child) {
        margin-right: 43px;
        @media (max-width: 767px) {
          margin-right: 37px;
        }
      }
      &:not(&:first-child) {
        &:before {
          content: "";
          position: absolute;
          width: 17px;
          height: 17px;
          left: -29px;
          top: 0;
          bottom: 0;
          margin: auto;
          background: url("../../../assets/images/mark.svg") no-repeat;
          background-size: contain;
          z-index: 1;
          @media (max-width: 767px) {
            width: 12px;
            height: 11px;
            left: -25px;
          }
        }
      }
    }
  }
  &--check {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.3;
    color: $main_color;
    display: flex;
    flex-direction: column;
    list-style: none;
    @media (max-width: 767px) {
      font-size: 15px;
    }
    li {
      position: relative;

      &:not(&:last-child) {
        margin-bottom: 15px;
        @media (max-width: 767px) {
          margin-bottom: 12px;
        }
      }
      &:before {
        content: "";
        position: absolute;
        width: 17px;
        height: 17px;
        left: -29px;
        top: 0;
        bottom: 0;
        margin: auto;
        background: url("../../../assets/images/check.svg") no-repeat;
        background-size: contain;
        z-index: 1;
      }
    }
  }
}
