@import "../../../styles/variables";
.reducing-section {
  $self: &;
  position: relative;
  padding: 65px 0 65px;
  overflow: hidden;
  background: $addition_color;
  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }

  &__header {
    width: 100%;
    text-align: left;

    span {
      color: $primary_color;
      text-transform: uppercase;
    }
  }

  .col-lg-6 {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__items {
    position: relative;
    display: grid;
    gap: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 440px;
    width: 100%;
    margin-top: 25px;
    @media (max-width: 767px) {
      grid-template-columns: 1fr 1fr;
      max-width: 282px;
      margin: 25px auto 0;
    }
  }
}
