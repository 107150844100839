@import '../../../styles/_variables';
.social {
  position: relative;
  display: flex;
  gap:12px;
  &__item{
    display: flex;
    svg{
      width: 30px;
      height: 30px;
      path{
        transition: all 0.3s ease;
      }
    }
    &:nth-child(2n){
      svg{
        path{
          fill:$addition_color
        }
      }
    }
    &:hover{
      svg{
        opacity: 0.9;
      }
    }
  }

}