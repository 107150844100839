@import "../../../styles/variables";

.online-section {
  &-title {
    margin-bottom: 32px;

    font-size: 42px;
    font-style: normal;
    font-weight: 600;

    span {
      text-transform: uppercase;
      color: $primary_color;
    }
  }

  &-description {
    font-size: 24px;
    font-weight: 600;
  }

  .row > &-items {
    position: relative;
    margin-top: 50px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 992px) {
      flex-direction: column;
    }
  }

  &-item {
    display: flex;
    flex: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      transition: all 0.3s ease;
      object-fit: cover;
      &:hover {
        transform: scale(1.05);
      }
    }

    &__text {
      margin-top: 48px;
      font-size: 16px;
      font-weight: 400;
    }
  }
}

@media (max-width: 992px) {
  .online-section {
    &-items {
      flex-direction: column;
    }

    &-item {
      &:first-child {
        margin-bottom: 30px;
      }

      img {
        order: 2;
      }

      &__text {
        order: 1;
        margin-top: 0;
        margin-bottom: 28px;
      }
    }
  }
}

@media (max-width: 767px) {
  .online-section {
    &-title {
      margin-bottom: 16px;
      font-size: 26px;
    }

    &-description {
      font-size: 20px;
    }

    .row > &-items {
      margin-top: 16px;
    }

    &-item {
      img {
        width: 300px;
      }
    }
  }
}
