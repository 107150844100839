@import "../../../styles/variables";
.online-section {
  $self: &;
  position: relative;
  padding: 65px 0 45px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 30px 0 15px;
  }
  &__image {
    position: relative;
    width: 520px;
    height: 737px;
    background: #f2f0e4;
    border-radius: 27px;
    overflow: hidden;
    background-size: 200%;
    margin-top: 30px;
    margin-right: 10px;
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    @media (max-width: 992px) {
      width: 100%;
      height: 630px;
    }
    @media (max-width: 767px) {
      margin-top: 15px;
      height: 445px;
    }
    img {
      position: absolute;
      top: 50px;
      left: 45%;
      transform: translateX(-50%);
      width: 450px;
      transition: all 0.3s ease;
      &:hover {
        transform: translateX(-50%) scale(1.02);
      }
      @media (max-width: 992px) {
        top: 20px;
        width: 400px;
      }
      @media (max-width: 767px) {
        width: 260px;
      }
    }
    &-title {
      position: absolute;
      bottom: 70px;
      left: 0;
      right: 0;
      font-size: 36px;
      line-height: 110%;
      font-weight: 600;
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 1200px) {
        //   font-size: 32px;
        max-width: 400px;
        bottom: 35px;
      }
      @media (max-width: 767px) {
        font-size: 28px;
        max-width: 250px;
      }
    }
  }
  &__online {
    position: relative;
    @media (max-width: 767px) {
      max-width: 285px;
      width: 100%;
      margin: 0 auto;
    }
    &-title {
      font-size: 16px;
      text-transform: uppercase;
      margin-bottom: 10px;
      @media (max-width: 767px) {
        font-size: 13px;
        margin-bottom: 0px;
        margin-bottom: -15px;
      }
      span {
        font-weight: 600;
      }
    }
    &-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;
      @media (max-width: 767px) {
        gap: 13px;
      }
    }
    &-word {
      svg {
        @media (max-width: 767px) {
          width: 195px;
        }
      }
    }
    &-list {
      position: relative;
      top: -2px;
      &__block {
        display: flex;

        &-item {
          font-size: 20px;
          font-weight: 900;
          text-align: center;

          padding: 4px 16px;
          border: solid $main_color 2px;
          border-radius: 26px;
          margin-bottom: 8px;
          margin-right: 8px;
        }
      }
      @media (max-width: 767px) {
        font-size: 13px;
        &__block {
          flex-direction: column;

          &-item {
            font-size: 13px;
            padding: 1px 10px;
            margin-right: 0px;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  &__inner {
    padding-top: 150px;
    padding-left: 40px;
    @media (max-width: 1200px) {
      padding-left: 10px;
    }
    @media (max-width: 992px) {
      padding-top: 60px;
    }
    @media (max-width: 767px) {
      padding-top: 25px;
      padding-left: 0;
    }
  }

  &__subtitle {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    color: $main_color;
    margin-bottom: 20px;
    line-height: 1.1;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-bottom: 12px;
    }
  }
  &__header {
    margin-bottom: 23px;
    @media (max-width: 767px) {
      margin-bottom: 10px;
    }
    h2 {
      color: $main_color;
      font-size: 48px;
      font-family: "Bebas", sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      @media (max-width: 767px) {
        font-size: 37px;
      }
      span {
        color: $primary_color;
      }
    }
  }
  &__description {
    position: relative;
    font-size: 16px;
    margin-bottom: 25px;
    @media (max-width: 767px) {
      font-size: 15px;
      margin-bottom: 20px;
    }

    p {
      margin-bottom: 12px;
      line-height: 1.4;
      @media (max-width: 767px) {
        margin-bottom: 12px;
      }
    }
  }
  &__benefits {
    position: relative;
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
  }
  &__benefit {
    position: relative;
    width: 232px;
    height: 96px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &:first-child {
      background: #f5e8fe;
      #{$self}__benefit-subtitle {
        color: #9c22f8;
      }
    }
    &:last-child {
      background: #e6f2ff;
      #{$self}__benefit-subtitle {
        color: #0075ff;
      }
    }
    &-wrapper {
      display: flex;
      width: 100%;
      justify-content: "center";
      align-items: center;
      flex-direction: column;
    }
    &-subtitle {
      font-weight: 700;
      font-size: 14px;
      line-height: 1.5;
      letter-spacing: 0.05em;
      text-transform: uppercase;
    }
    &-title {
      font-weight: 600;
      font-size: 26px;
      text-transform: uppercase;

      color: #313131;
    }
    &-free-plan-title {
      font-size: 36px;
      line-height: 35px;
    }
    &-description {
      font-weight: 500;
      font-size: 12px;
      line-height: 1.36;
      text-align: center;
    }
  }
}
