@import "../../../styles/_variables";
.header {
  $self: &;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 101;
  min-height: 87px;
  @media (max-width: 1078px) {
    display: none;
  }
  &__wrapper {
    width: 100%;
    // background: #FFFFFF;
    transition: all 0.3s ease;
  }
  &__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    transition: all 0.3s ease;
    @media (max-width: 767px) {
      padding: 16px 0;
    }
  }
  &__info {
    display: flex;
    align-items: center;
    gap: 35px;
  }
  &__lang {
    position: relative;
    justify-self: end;
    margin-left: auto;
    font-size: 16px;
    z-index: 101;
    display: none;
    //  display: none;
    @media (max-width: 767px) {
      margin-right: 30px;
    }
    &:hover {
      #{$self}__lang-list {
        display: flex;
      }
    }
  }
  &__menu {
    display: flex;
    gap: 35px;
    padding-right: 55px;
    @media (max-width: 767px) {
      gap: 15px;
    }
    a {
      position: relative;
      display: inline-flex;
      color: $main_color;
      text-decoration: none;
      // text-transform: uppercase;
      font-weight: 600;
      transition: all 0.3s ease;
      cursor: pointer;
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        transform: scaleX(1);
        left: 0;
        bottom: -1px;
        background: $main_color;
        transform-origin: bottom left;
        transition: transform 0.25s ease-out;
      }
      &:hover {
        opacity: 1;
        &:before {
          width: 100%;
          transform: scaleX(0);
          transform-origin: bottom right;
        }
        svg {
          transform: rotate(135deg) translateX(1px) translateY(-1px);
        }
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
      &:hover {
        color: $primary_color;
      }
    }
  }
  &__logo {
    align-items: center;
    text-decoration: none;
    &-image {
      width: 92px;
      height: 92px;
    }
  }
  &__circle {
    position: absolute;
    right: -55px;
    top: -10px;
    width: 40px;
    height: 40px;
    background: $primary_color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    svg {
      position: relative;
      right: 0px;
      top: -1px;
      width: 13px;
      height: 13px;
      transition: all 0.3s ease;
      transform: rotate(135deg);
    }
  }
  &.active {
    padding-top: 0;
    #{$self}__inner {
      padding: 16px 0;
      @media (max-width: 767px) {
        padding: 8px 0;
      }
    }
    #{$self}__logo {
      > svg {
        width: 40px;
        height: 40px;
      }
      &-text {
        > svg {
          width: 240px;
        }
      }
    }
    #{$self}__wrapper {
      position: fixed;
      background: $addition_color;
    }
    &.out {
      #{$self}__wrapper {
        transform: translateY(-100%);
      }
    }
  }
  &.active-menu {
    @media (max-width: 992px) {
      #{$self}__wrapper {
        box-shadow: none;
      }
      background: #fff;
      height: 100vh;
      overflow-y: auto;
    }
  }
}
