@import "../../../styles/variables";
.thinking-section {
  $self: &;
  position: relative;
  padding: 65px 0 50px;
  overflow: hidden;
  background: #f2f0e4;
  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    @media (max-width: 992px) {
      padding-top: 0;
      margin-bottom: 40px;
    }
    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }
  &__header {
    max-width: 610px;
    width: 100%;
  }
  &__description {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-top: 15px;
    }
    span {
      color: $primary_color;
    }
  }
  &__button {
    position: relative;
    margin-top: 25px;
    a {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-weight: 700;
      font-size: 24px;
      color: $primary_color;
      margin-left: 46px;
      transition: all 0.3s ease;
      @media (max-width: 767px) {
        font-size: 20px;
        margin-left: 36px;
      }
      &:before {
        background: $primary_color;
      }
      &:hover {
        color: $main_color;
        svg {
          transform: rotate(0deg);
          path {
            fill: $primary_color;
          }
        }
      }
      svg {
        position: absolute;
        width: 23px;
        height: 22px;
        transform: rotate(45deg);
        left: -42px;
        transition: all 0.3s ease;
        @media (max-width: 767px) {
          width: 18px;
          height: 17px;
          left: -32px;
        }
        path {
          transition: all 0.3s ease;
          fill: $main_color;
        }
      }
    }
  }

  img {
    position: relative;
    width: 445px;
    height: 569px;
    transition: all 0.3s ease;
    @media (max-width: 992px) {
      width: 100%;
      height: 100%;
    }
    &:hover {
      transform: scale(1.02);
    }
  }
}
