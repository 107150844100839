@import "../../../styles/variables";
.statistics-section {
  $self: &;
  position: relative;
  padding: 65px 0 40px;
  overflow: hidden;
  background: $addition_color;
  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 220px;
    @media (max-width: 992px) {
      padding-top: 0px;
    }
  }
  &__header {
    max-width: 610px;
    width: 100%;
  }
  &__description {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-top: 10px;
    }
  }
  &__content {
    position: relative;
    margin-top: 40px;
    font-size: 16px;
    max-width: 475px;
    width: 100%;
    @media (max-width: 992px) {
      margin-bottom: 30px;
    }
    @media (max-width: 767px) {
      font-size: 15px;
      margin-top: 15px;
    }
  }
  &__image {
    display: flex;
    position: relative;
    width: 520px;
    border-radius: 27px;
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    @media (max-width: 992px) {
      margin: 0 auto;
    }
    @media (max-width: 767px) {
      max-width: 335px;
    }
    img {
      position: relative;
      left: -5px;
      top: -15px;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      @media (max-width: 992px) {
        top: 0;
      }
      @media (max-width: 767px) {
        left: -25px;
      }
      &:hover {
        transform: scale(1.02);
      }
    }
  }
}
