@import '../../../styles/variables';
.contacts-section{
  $self:&;
  position:relative;
  padding: 65px 0 45px;
  overflow: hidden;

  @media (max-width:767px){
    padding: 30px 0 15px;
  }

  &__inner{
    position: relative;
    margin: 100px 0;
    min-height: 60vh;
    display: flex;
    align-items: center;
    @media (max-width:1200px){
      padding-left: 10px;

    }
    @media (max-width:992px){
      padding-top: 60px;
    }
    @media (max-width:767px){
      padding-top:25px;
      padding-left: 0;
      margin: 50px 0;
    }
  }

  &__header{
    margin-bottom: 23px;
    @media (max-width:767px){
      margin-bottom: 10px;
    }
    h2{
      color: $main_color;
      font-size: 48px;
      font-family: 'Bebas',sans-serif;
      font-style: normal;
      font-weight: 400;
      line-height: 1.1;
      @media (max-width:767px){
        font-size: 37px;
      }
      span{
        color:$primary_color;
      }
    }
  }
  &__items{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-items: center;
    justify-content: center;
    gap:20px;
    @media (max-width:992px){
      grid-template-columns: 1fr;
    }
    @media (max-width:767px){
      grid-template-columns: 1fr;
    }
  }
  &__item{
  //  width: 33%;
  }
  &__description{
    position: relative;
    font-size: 16px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    @media (max-width:767px){
      font-size: 15px;
      margin-bottom: 20px;
    }

    a{
      position: relative;
      display: inline-flex;
      margin-bottom: 12px;
      line-height: 1.4;
      color:$main_color;
      margin-left: 35px;
      &:hover{
        color:$primary_color;
        &:before{
          background: $primary_color;
        }
        svg{
          path{
            stroke:none;
            fill:$main_color
          }
        }
      }
      svg{
        position: absolute;
        left: -35px;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        path{
          fill:$main_color
        }
      }
      @media (max-width:767px){
        margin-bottom: 12px;
      }
    }
  }
}

