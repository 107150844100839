@import "../../../styles/variables";
.get-all-section {
  $self: &;
  position: relative;
  padding: 65px 0 65px;
  overflow: hidden;
  background: $addition_color;
  img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .get-all-section {
    padding: 30px 0 15px;

    .col-lg-6 {
      margin-top: -90px;
    }

    .get-all-section__item-exclude {
      margin-top: 0px;
    }
  }
}
