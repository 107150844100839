@import "../../../styles/variables";
.payment-section {
  $self: &;
  position: relative;
  padding: 65px 0 65px;
  overflow: hidden;
  background: $addition_color;
  @media (max-width: 767px) {
    padding: 30px 0 30px;
  }
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__header {
    max-width: 610px;
    width: 100%;
  }
  &__inner {
    @media (max-width: 992px) {
      margin: 30px 0;
    }
  }
  &__description {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    @media (max-width: 767px) {
      font-size: 20px;
      margin-top: 15px;
    }
  }
  &__payments {
    margin-top: 45px;
    @media (max-width: 767px) {
      margin-top: 0px;
      img {
        width: 100%;
      }
    }
  }
  &__list {
    margin-top: 40px;
    padding-left: 30px;
    @media (max-width: 767px) {
      margin-top: 15px;
    }
  }
  &__image {
    display: flex;
    position: relative;
    width: 420px;
    height: 420px;
    border-radius: 27px;
    overflow: hidden;
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      height: 320px;
    }
    img {
      position: relative;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      object-fit: cover;
      &:hover {
        transform: scale(1.05);
      }
    }
  }
}
