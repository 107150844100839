@import "../../../styles/variables";
.questions-section {
  $self: &;
  position: relative;
  padding: 65px 0 65px;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 30px 0 32px;
  }
  &__inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__header {
    max-width: 610px;
    width: 100%;
  }
  &__description {
    position: relative;
    font-size: 24px;
    font-weight: 600;
    margin-top: 32px;
    @media (max-width: 992px) {
      margin-bottom: 50px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
      margin-top: 15px;
      margin-bottom: 20px;
    }
    span {
      color: $primary_color;
    }
  }
}
