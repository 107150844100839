@import '../../../styles/_variables';
.plate{
  $self:&;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84px;
  height: 84px;
  border-radius: 20px;
  overflow: hidden;
  transition: all 0.3s ease;
  &:hover{
    transform: scale(1.05);
  }
  @media (max-width:767px){
    width: 76px;
    height: 76px;
  }
  &:before{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(136.8deg, rgba(214, 214, 214, 0.47) 0%, rgba(255, 255, 255, 0) 115.8%);
    border-radius: 20px;
    backdrop-filter: blur(42px);

    z-index: 1;
  }
  &:after{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(136.08deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 103.86%);
    border-radius: 20px;
    backdrop-filter: blur(42px);
    z-index: 0;
  }


  &__wrapper{
    position: relative;
    z-index: 2;
  }
  &__icon{
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
  }
  &__title{
    position: relative;
    font-weight: 600;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
  }
  &--big{
    width: 136px;
    height: 136px;
    #{$self}__title{
      font-size:15px
    }
  }
}