@import "../../../styles/variables";
.main {
  $self: &;
  position: relative;
  padding: 120px 0 65px;
  background: $addition_color;
  overflow: hidden;
  @media (max-width: 767px) {
    padding: 19px 0 30px;
  }
  &__image {
    position: relative;
    width: 520px;
    height: 500px;
    background: #f2f0e4;
    border-radius: 27px;
    overflow: hidden;
    background-size: 200%;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: 0px 0px 20.4px -6px rgba(24, 39, 75, 0.12);
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }
    @media (max-width: 767px) {
      box-shadow: 0px 5px 14px 0px rgba(38, 52, 117, 0.15);
      height: 350px;
    }
    &-title {
      position: absolute;
      top: 30px;
      left: 0;
      right: 0;
      font-weight: 600;
      font-size: 22px;
      line-height: 140%;
      letter-spacing: 0.02em;
      text-align: center;
      text-transform: uppercase;
      color: $main_color;
      @media (max-width: 767px) {
        top: 17px;
        font-size: 14px;
      }
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 520px;
      transition: all 0.3s ease;
      &:hover {
        transform: scale(1.02);
      }
      @media (max-width: 767px) {
        width: 320px;
        bottom: 25px;
      }
    }
  }
  &__button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    display: flex;
    @media (max-width: 767px) {
      bottom: 25px;
      right: 25px;
    }
  }
  &__btn {
    position: relative;
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 45px;
    a {
      font-size: 16px;
      color: $main_color;
      font-weight: 500;
      text-decoration: none;
    }
  }
  &__circle {
    position: absolute;
    right: -55px;
    top: -10px;
    width: 40px;
    height: 40px;
    background: $primary_color;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    svg {
      position: relative;
      right: 0px;
      top: -1px;
      width: 13px;
      height: 13px;
      transition: all 0.3s ease;
      transform: rotate(135deg);
    }
  }
  &__wrapper {
    padding-top: 140px;
    @media (max-width: 992px) {
      padding-top: 60px;
    }
    @media (max-width: 767px) {
      padding-top: 25px;
    }
  }
  &__description {
    margin-top: 20px;
    font-size: 26px;
    line-height: 130%;
    font-weight: 600;
    max-width: 470px;
    width: 100%;
    @media (max-width: 767px) {
      font-size: 18px;
      max-width: 370px;
    }
  }
  &__header {
    @media (max-width: 992px) {
      max-width: 470px;
    }
    @media (max-width: 767px) {
      max-width: 370px;
    }
  }
}
