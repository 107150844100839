@import './_variables';
@import "./_scrollbar";
@import "./_fonts";

.scroll-container{
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.progress-bar{
  position: fixed;
  left:0;
  top:0;
  height: 3px;
  background: $primary_color;
  z-index: 100;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%; /* Sets up the Base 10 stuff */
  min-width: 360px;
  &.hidden-scroll{
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}

body {
  font-family: "Inter Tight", sans-serif;
  font-size: 16px;
  font-weight: 400;
  -webkit-text-size-adjust: none;
  color:$main_color;
  &.hidden-scroll{
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}

.hidden{
  opacity: 0;
}

main{
  overflow: hidden;
}

.link-line{
  position: relative;
  display: inline-block;
  text-decoration: none;

  &:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    transform: scaleX(0);
    left: 0;
    bottom: -1px;
    background: $main_color;
    transform-origin: bottom right;
    transition: transform .25s ease-out;
  }
  &--reverse{
    position: relative;
    display: inline-block;
    text-decoration: none;
    svg{
      position: relative;
      top: -1px;
      width: 15px;
      height: 15px;
      transition: all 0.3s ease;
    }
    &:before{
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      transform: scaleX(1);
      transform-origin: bottom left;
      left: 0;
      bottom: -1px;
      background: $main_color;
      transition: transform .25s ease-out;
    }
    &:hover{
      color: $primary_color;
      svg{
        path{
          stroke: $primary_color;
        }
      }
      &:before{
        background: $primary_color;
        transform: scaleX(0);
        transform-origin: bottom right;
      }
    }
  }
  &:hover{
    &:before{
      width: 100%;
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }
}

.container{
  max-width: 1295px;
  @media (max-width: 1500px) {
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    padding: 0 20px;
  }
}

.wrapper{
  position: relative;
  max-width: 1040px;
  margin:0 auto;
  width: 100%;
  @media (max-width:992px){
    max-width: 550px;
  }
  @media (max-width:767px){
    max-width: 450px;
  }
}

.policy{
  position: relative;
  padding: 140px 0;
  @media (max-width:767px){
    padding: 100px 0;
  }
  &__wrapper{
    padding-left: 110px;
    @media (max-width:1200px){
      padding-left:0px;
    }
    @media (max-width:992px){
      padding-left: 30px;
    }
    @media (max-width:767px){
      padding-left: 10px;
    }
  }
  strong{
    font-size: 18px;
    @media (max-width:767px){
      font-size: 16px;
      font-weight: 500;
    }
  }
  p{
    margin-top: 10px;
    margin-bottom: 10px;

  }
  ol{
    li{
      margin-left: 20px;
      &::marker{
        font-size: 18px;
      }
    }

  }
  ul{
    padding-left: 17px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  a{
    color:$primary_color;
    &:hover{
      color:$main_color
    }
  }
}

.h1{
  position: relative;
  color: $main_color;
  font-family: 'Bebas',sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-transform: uppercase;
  @media (max-width:767px){
    font-size: 40px;
  }

  span{
    color: $primary_color;
  }
}

.h2{
  color: $main_color;
  font-size: 42px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  @media (max-width:767px){
    font-size: 28px;
  }
  span{
    color: $primary_color;
  }
}