@import "../../../styles/_variables";
.footer {
  position: relative;
  /* padding-top: 70px;
  @media (max-width:767px){
    padding-top: 48px;
  }*/
  &__logo {
    position: relative;
    display: flex;
    justify-content: center;
  }
  &__feedback {
    position: relative;
    display: flex;
    gap: 17px;
    padding-top: 20px;
    justify-content: space-evenly;
    a {
      &:nth-child(2) {
        svg {
          path {
            fill: #41c452;
          }
        }
      }
      &:nth-child(3) {
        svg {
          path {
            fill: #039be5;
          }
        }
      }
      &:hover {
        opacity: 0.7;
      }
    }
    svg {
      width: 29px;
      height: 29px;
    }
  }
  &__download {
    position: relative;
    gap: 10px;
    margin-top: 20px;
    display: flex;

    a {
      transition: all 0.3s ease;
      width: 100px;
      img {
        width: 100%;
      }
      &:hover {
        opacity: 0.8;
      }
      @media (max-width: 767px) {
        width: 140px;
      }
    }
  }
  &__info {
    position: relative;
    padding: 32px 0;
    background: $addition_color;
    @media (max-width: 767px) {
      padding: 32px 0 25px;
    }
    &-feed {
      position: relative;
      display: flex;
      align-items: center;
      &-wrapper {
        @media (max-width: 992px) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
    &-wrapper {
      display: flex;
      gap: 88px;
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      @media (max-width: 992px) {
        flex-direction: column;
        align-items: center;
        gap: 40px;
      }
      @media (max-width: 767px) {
        max-width: 220px;
        gap: 24px;
      }
    }
    &-title {
      position: relative;
      font-weight: 700;
      font-size: 18px;
      line-height: 130%;
      color: #232323;
      margin-bottom: 10px;
      @media (max-width: 992px) {
        text-align: center;
      }
    }
    &-list {
      position: relative;
      @media (max-width: 992px) {
        text-align: center;
        max-width: 400px;
        width: 100%;
      }
      ul {
        list-style: none;
        li {
          margin-bottom: 15px;
          a {
            color: $main_color;
            text-decoration: none;
            &:hover {
              color: $primary_color;
              &:before {
                background: $primary_color;
              }
            }
          }
        }
      }
    }
  }
  &__info-copy {
    position: relative;
    background: $main_color;
    padding: 32px 0;
    &-wrapper {
      display: flex;
      max-width: 320px;
      width: 100%;
      margin: 0 auto 15px;
      align-items: center;
      @media (max-width: 767px) {
        flex-direction: column;
      }
    }
    &-logo {
      position: relative;
      align-items: center;
      font-weight: 900;
      font-size: 20px;
      line-height: 24px;
      text-align: left;
      text-transform: uppercase;
      color: $addition_color;
      margin-left: 25px;
      @media (max-width: 767px) {
        margin-left: 0px;
      }
      span {
        color: $primary_color;
      }
    }
  }
  &__social {
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  &__copy {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 400;
  }
}
