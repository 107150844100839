@import '../../../styles/variables';
.place-section{
  $self:&;
  position:relative;
  padding: 65px 0 65px;
  overflow: hidden;
  background: $addition_color;
  @media (max-width:767px){
    padding: 30px 0 30px;
  }
  &__wrapper{
    position:relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    @media (max-width:767px){
      padding-top:0;
    }
  }
  &__header{
    max-width: 610px;
    width: 100%;
  }
  &__description{
    position: relative;
    font-size: 24px;
    margin-top: 30px;
    font-weight: 600;
    @media (max-width:767px){
      font-size: 20px;
      margin-top: 15px;
    }
  }

  &__image{
    display: flex;
    position: relative;
    width: 420px;
    height: 420px;
    border-radius: 27px;
    overflow: hidden;
    @media (max-width:992px){
      height: 531px;
      width: 100%;
    }
    @media (max-width:767px){
      height: 471px;
    }
    img{
      position: relative;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease;
      object-fit: cover;
      &:hover{
        transform: scale(1.05);
      }
    }
  }
  &__image-wrapper{
    position: relative;
    @media (max-width:992px){
      margin-top: 25px;
    }

  }
  &__items{
    position: relative;
    display: grid;
    gap:16px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    max-width: 385px;
    width: 100%;
    margin-top: 35px;
    @media (max-width:992px){
      position: absolute;
      top:25px;
      left: 10px;
      margin-top: 0px;
      grid-template-columns: repeat(4, 1fr); /* Определяем четыре колонки */
      grid-template-rows: auto auto auto auto auto;
    }
    @media (max-width:767px){
      gap:4px;
      top:45px;
      left: 2px;
      max-width: 310px;
    }
  }
  &__item{
    transition: all 0.3s ease;
    @media (max-width:992px){
      grid-column: span 4;
      &:nth-child(n+5) {
        grid-column: auto;
      }
    }
    &:hover{
      transform: scale(1.1);
    }
  }
}

