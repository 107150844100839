@import '../../../styles/_variables';
.accordion{
  $self: &;
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease;
  margin: 0px 0 32px;

  @media (max-width:767px){
    margin: 0px 0 24px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &:last-child{
    border-bottom: none;
    margin-bottom: 0;
  }
  &__name{
    position: relative;
    color: $main_color;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    transition: all 0.3s ease;
    @media (max-width:767px){
      font-size: 18px;
    }
  }
  &__answer{
    position: relative;

    grid-template-rows: 0fr;
    overflow: hidden;
    font-size: 16px;
    color: $main_color;
    font-style: normal;
    font-weight: 500;
    transition:grid-template-rows 0.3s, padding 0.3s;
    display: grid;
    line-height: 1.36;
    p{
      &:not(:last-child){
        margin-bottom: 8px;
      }
    }
    @media (max-width:767px){
      font-size: 16px;
    }
    &-wrapper{
      min-height: 0;
      transition:all 0.3s;
      visibility: hidden;
      opacity: 0;
    }
  }
  &:hover {
    #{$self}__name {
      // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
      color:$primary_color;
    }
  }
  &.active {
   // padding-bottom: 30px;
    #{$self}__name {
      // box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
      color:$primary_color;
    }
    #{$self}__answer {
      opacity: 1;
      grid-template-rows: 1fr;
      padding-top: 32px;
      @media (max-width:767px){
        padding-top: 8px;
      }
      &-wrapper{
        visibility: visible;
        opacity: 1;
      }
    }
  }
}