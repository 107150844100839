@import "../../../styles/variables";
.customize-section {
  $self: &;
  position: relative;
  padding: 65px 0 95px;
  overflow: hidden;
  &__wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 180px;
  }
  &__header {
    max-width: 610px;
    width: 100%;

    h2 {
      width: 50%;
    }
  }

  &__item {
    margin-top: 50px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__text {
    margin-top: 38px;

    text-align: center;
    font-size: 24px;
    font-weight: 600;
  }

  &__second-image {
    margin-top: 46px;
  }

  img {
    height: 100%;
    transition: all 0.3s ease;
    &:hover {
      transform: scale(1.02);
    }
  }
}

@media (max-width: 992px) {
  .customize-section {
    &__wrapper {
      padding-top: 0;
    }

    &__header {
      h2 {
        width: 100%;
      }
    }

    &__item {
      &:first-child {
        .customize-section__text {
          order: 1;
        }

        img {
          order: 2;
        }
      }

      img {
        margin-top: 40px;
      }
    }

    &__second-image {
      margin-top: 0;
    }
  }
}

@media (max-width: 767px) {
  .customize-section {
    padding: 30px 0 30px;

    &__item {
      margin-top: 0;

      img {
        width: 80%;
        margin-top: 16px;
      }
    }

    &__text {
      font-size: 16px;
      align-self: flex-start;
      text-align: left;
    }
  }
}
